<template>
<svg width="96" height="40" viewBox="0 0 96 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.8835 40C30.865 40 39.767 31.0458 39.767 20C39.767 8.95416 30.865 0 19.8835 0C8.902 0 0 8.95416 0 20C0 31.0458 8.902 40 19.8835 40Z" fill="#D6001C"/>
  <path d="M11.1879 10.4001V17.1929C12.229 15.9598 13.4771 15.3391 14.9245 15.3391C15.6699 15.3391 16.3375 15.4805 16.9364 15.7609C17.537 16.0396 17.9859 16.3974 18.2891 16.8318C18.594 17.2702 18.8001 17.7503 18.9115 18.2795C19.022 18.8062 19.0773 19.6257 19.0773 20.7373V28.0001H15.8425V21.4587C15.8425 20.1607 15.7838 19.3387 15.6607 18.9892C15.5453 18.6469 15.3165 18.3536 15.0116 18.1572C14.7 17.9483 14.3122 17.8452 13.8439 17.8452C13.3079 17.8452 12.8271 17.9783 12.4091 18.2429C11.9819 18.5091 11.6754 18.9069 11.481 19.4385C11.2833 19.971 11.1845 20.7573 11.1879 21.7982L11.1845 28.0001H7.95386V10.4001H11.1887" fill="white"/>
  <path d="M23.9248 10.4001V17.1929C24.9667 15.9598 26.2139 15.3391 27.6605 15.3391C28.4035 15.3391 29.0761 15.4805 29.6758 15.7609C30.2738 16.0396 30.7228 16.3974 31.0243 16.8318C31.3285 17.2673 31.5404 17.7599 31.6467 18.2795C31.7582 18.8062 31.8135 19.6257 31.8135 20.7373V28.0001H28.5819V21.4587C28.5819 20.1607 28.5207 19.3387 28.3976 18.9892C28.2822 18.6464 28.0527 18.3529 27.7468 18.1572C27.4385 17.9483 27.0474 17.8452 26.5791 17.8452C26.0439 17.8452 25.5631 17.9783 25.1443 18.2429C24.723 18.5091 24.4148 18.9069 24.2179 19.4385C24.0236 19.971 23.9248 20.7573 23.9248 21.7982V28.0001H20.6941V10.4001H23.9248Z" fill="white"/>
  <path d="M51.2679 28.1251C49.074 28.1251 47.9769 26.9862 47.9769 24.7083V17.7146H46.2913V14.9117H47.9769V11.3882H51.1878V14.9117H53.3284V17.7146H51.1878V23.9609C51.1878 24.8507 51.5 25.2956 52.1242 25.2956C52.57 25.2956 52.8912 25.171 53.0873 24.9219L53.7565 27.3777C53.2035 27.876 52.374 28.1251 51.2679 28.1251Z" fill="white"/>
  <path d="M61.0469 28.125C59.2276 28.125 57.7649 27.5022 56.6589 26.2564C55.5709 25.0107 55.0269 23.3735 55.0269 21.3448C55.0269 19.4229 55.5528 17.8212 56.6054 16.5399C57.6577 15.2408 59.0579 14.5913 60.8061 14.5913C62.5543 14.5913 63.9188 15.232 64.8999 16.5132C65.8811 17.7767 66.3716 19.4317 66.3716 21.4783V22.3859H58.2641C58.3356 23.3112 58.6388 24.0676 59.1741 24.6548C59.7091 25.2421 60.4495 25.5357 61.3949 25.5357C61.8764 25.5357 62.376 25.4289 62.8933 25.2154C63.4105 24.984 63.8474 24.6726 64.2043 24.2811L65.6757 26.2031C64.4985 27.4843 62.9557 28.125 61.0469 28.125ZM63.2676 20.197C63.2319 19.3962 63.0182 18.6932 62.6255 18.0882C62.2331 17.4831 61.6089 17.1806 60.7527 17.1806C59.9322 17.1806 59.3167 17.4831 58.9063 18.0882C58.5139 18.6932 58.2999 19.3962 58.2641 20.197H63.2676Z" fill="white"/>
  <path d="M74.0543 28.125C72.2884 28.125 70.8614 27.4932 69.7731 26.2297C68.6851 24.9662 68.1411 23.3379 68.1411 21.3448C68.1411 19.3695 68.6851 17.7501 69.7731 16.4866C70.8614 15.2231 72.2884 14.5913 74.0543 14.5913C75.8382 14.5913 77.2117 15.1697 78.1748 16.3264L76.5695 18.542C75.9631 17.7946 75.2405 17.4208 74.4022 17.4208C73.4926 17.4208 72.7699 17.7946 72.2349 18.542C71.7177 19.2716 71.4589 20.2059 71.4589 21.3448C71.4589 22.5015 71.7177 23.4536 72.2349 24.201C72.7699 24.9307 73.4926 25.2955 74.4022 25.2955C75.2405 25.2955 75.9631 24.9217 76.5695 24.1743L78.1748 26.3899C77.2117 27.5466 75.8382 28.125 74.0543 28.125Z" fill="white"/>
  <path d="M90.5608 27.8047H87.3499V19.2627C87.3499 18.0348 86.7792 17.4208 85.6375 17.4208C85.1559 17.4208 84.7101 17.581 84.2997 17.9013C83.8896 18.2038 83.5596 18.5776 83.3099 19.0225V27.8047H80.0723V10H83.3099V16.5933C83.6665 16.0417 84.1838 15.5701 84.8617 15.1786C85.5573 14.7871 86.2977 14.5913 87.0824 14.5913C89.4013 14.5913 90.5608 15.748 90.5608 18.0615V27.8047Z" fill="white"/>
</svg>
</template>