<template>
<svg xmlns="http://www.w3.org/2000/svg" width="36" height="25" viewBox="0 0 36 25" fill="none">
  <rect y="0.5" width="36" height="4.2" />
  <rect x="14.4" y="20.3" width="21.6" height="4.2" />
  <rect y="10.7" width="36" height="4.2" />
</svg>
</template>

<style scoped>
  rect {
    fill: theme('colors.red.300')
  }
</style>