import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeLayout from '../layouts/HomeLayout.vue'
import MainView from "@/views/MainView.vue";
import { useQuizStore } from '@/stores/index'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'main',
        component: HomeLayout,
        children: [
            {
                path: '',
                name: 'homepage',
                component: MainView,
                meta: {
                    hasBackgroundImg: true,
                },
            },
            {
                path: 'rating',
                name: 'rating',
                component: () => import('../views/RatingView.vue'),
            },
            {
                path: '/404',
                name: 'not-found',
                component: () => import('../views/PageNotFound.vue'),

            }
        ]
    }, {
        path: '/auth',
        name: 'auth',
        component: HomeLayout,
        children: [
            {
                path: 'sign-up',
                name: 'sign-up',
                component: () => import('../views/SignUpView.vue'),
                meta: {
                    hasBackgroundImg: true,
                },
            },
            {
                path: 'sign-in',
                name: 'sign-in',
                component: () => import('../views/SignInView.vue'),
                meta: {
                    hasBackgroundImg: true,
                },
            },
            {
                path: 'confirm',
                name: 'confirm',
                component: () => import('../views/ConfirmEmailView.vue'),
                meta: {
                    hasBackgroundImg: true,
                    auth: true,
                },
            },
            {
                path: 'form',
                name: 'form',
                meta: {
                    auth: true,
                },
                component: () => import('../views/FormView.vue'),
            },
        ]
    },
    {
        path: '/game',
        name: 'game',
        redirect: '/game/rules',
        component: HomeLayout,
        children: [
            {
                path: 'rules',
                name: 'rules',
                meta: {
                    auth: true,
                },
                component: () => import('../views/RulesView.vue'),
            },
            {
                path: 'tasks/:nominationId',
                name: 'tasks',
                meta: {
                    auth: true,
                },
                component: () => import('../views/TasksView.vue'),
            },

        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const store = useQuizStore()

    if (from === to) {
        return false
    }

    if (!to.matched.length) {
        next('/404')
    }

    const guardRoute = to.matched.some(record => record.meta.auth)
    const authorized = store.client?.authorized
    const confirmed = Number(localStorage.getItem('userConfirmed')) === 1
    const formCompleted = store.client?.formCompleted || Number(localStorage.getItem('userForm')) === 1
    const joined = store.client?.joined || false
    const withinAuthPage = ['sign-up', 'sign-in', 'confirm', 'form'].includes(to.name as string)

    if (guardRoute) {
        if (!authorized) {
            if (to.name !== 'sign-up') {
                next({path: "/auth/sign-up"})
                return false
            }
        } else if (!confirmed) {
            if (to.name !== 'confirm') {
                next('/auth/confirm')
                return false
            }
        } else if (!formCompleted) {
            if (to.name !== 'form') {
                next('/auth/form')
                return false
            }
        }
    }

    if (withinAuthPage && authorized && confirmed && formCompleted) {
        next('/game/rules')
        return false
    }
    
    next()
})
export default router
