import { defineStore } from 'pinia'
import CompetitionClient from "cnr-client"
import { INomination, IFormResponse, IRatingPosition, IRating, IRatingsListResponse, IUser, IContestShort, IQuestion, IRatingResponse } from "cnr-client/src/interfaces"
import { ref } from 'vue'

interface INoms {
  id: number
  count: number
}

export const useQuizStore = defineStore('quiz', () => {
  let client = ref(undefined as CompetitionClient | undefined)
  let user = ref(undefined as IUser | undefined)
  let contest = ref(undefined as IContestShort | undefined)
  let leaderboard = ref(undefined as IRatingPosition[] | undefined)
  let leaderboards = ref(undefined as IRatingsListResponse | undefined)
  let nominations = ref(undefined as INomination[] | undefined)
  let formQuestions = ref(undefined as IQuestion[] | undefined)
  let formCompleted = ref(false)
  let nominationState = ref([] as INoms[])
  let userEmail = ref<String>('')

  const initClient = () => {
    const options = {
      contestId: process.env.VUE_APP_CONTEST_ID,
      apiEndpoint: process.env.VUE_APP_ENDPOINT_URL,
      ssoUrl: ''
    }

    client.value = new CompetitionClient(options);

    const params = (new URL(document.location.toString())).searchParams;
    const token = params.get('token');
    
    if (token) {
      client.value.setToken(token);
      window.location.href = `${window.location.origin}/game/rules`
    }
  }

  const getUser = () => {
    const prom = client.value?.getProfile()
    prom?.then((userData) => {
      setUserEmail(userData.email)
      localStorage.setItem('userConfirmed', String(userData?.confirmed))

      if (!!client?.value?.formCompleted && !localStorage.getItem('userForm')) {
        localStorage.setItem('userForm', String(client?.value?.formCompleted))
      }
      user.value = userData
    }).catch(() => {
      localStorage.removeItem('userConfirmed')
      localStorage.removeItem('userForm')
    })
    return prom
  }

  const setUserEmail = (email: string) => {
    userEmail.value = email
  }
  
  const getContest = async () => {
    return contest.value = await client.value?.getContest()
  }

  const getLeaderboardById = async (leaderboardId: number) => {
    await client.value?.getLeaderboardById(leaderboardId, 1, '')
      .then((resp) => {
        leaderboard.value = resp.leaderboard
      })
  }

  const getLeaderboards = async () => {
    await client.value?.getLeaderboards()
      .then((response: any) => {
        leaderboards.value = response;
        getLeaderboardById(response[0].id)
      })
      .catch((error) => {})
  }

  const getNominations = async () => {
    nominations.value = await client.value?.getNominations()
    nominations.value?.forEach((nom, i) => {
      nominationState.value[i] = { id: nom.id, count: Number(nom.solutions_count) }
    })
    return nominations.value
  }

  const incNominationState = async (nominationId: number) => {
    const index = nominationState.value?.findIndex(item => item.id === nominationId)
    nominationState.value[index].count++
  }
  
  const logout = () => {
    client.value?.logout()
    user.value = undefined
  }

  const getForm = async () => {
    const form = await client.value?.getForm() as IFormResponse;
    const { questions, is_completed } = form;
    formQuestions.value = questions
    formCompleted.value = is_completed
  }

  return { client, user, userEmail, contest, leaderboard, leaderboards, nominations, nominationState, formCompleted, formQuestions, getContest, getUser, getLeaderboards, getLeaderboardById, getNominations, incNominationState, getForm, setUserEmail, logout, initClient }
})